import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '../src/assets/css/newIndex.css'

Vue.config.productionTip = false

//http
import axios from 'axios'
Vue.prototype.$http = axios.create({
  baseURL: 'https://nxcodenet-10fd8f-1301115590.ap-shanghai.service.tcloudbase.com',
  timeout: 30000,
  headers: { 'X-Custom-Header': 'foobar' }
});



//云开发
import tcb from "@cloudbase/js-sdk";
const app = tcb.init({
  env: 'nxcodenet-10fd8f'
});
Vue.prototype.$serverLess = app

const auth = app.auth();
async function login() {
  await auth.anonymousAuthProvider().signIn();
  // 匿名登录成功检测登录状态isAnonymous字段为true
  const loginState = await auth.getLoginState();
  console.log(loginState.isAnonymousAuth); // true
}

login();



// const nxcodenet = tcb.init({
//   env: 'nxcodenet-10fd8f'
// });
// Vue.prototype.$nxcodenet=nxcodenet

// const authnxcodenet = nxcodenet.auth();
// async function loginnxcodenet() {
//   await authnxcodenet.anonymousAuthProvider().signIn();
//   // 匿名登录成功检测登录状态isAnonymous字段为true
//   const loginState = await authnxcodenet.getLoginState();
//   console.log(loginState.isAnonymousAuth); // true
// }

// loginnxcodenet();





Vue.use(ElementUI);
Vue.config.productionTip = false

export let vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')



Vue.prototype.$localStorage = {
  //存储
  set(key, value) {
    // window.removeEventListener("storage", this.clearState); //这里更改登陆状态，清理storage事件
    localStorage.setItem(key, JSON.stringify(value));
    // window.addEventListener("storage", this.clearState); //这里注册事件，监听storage更改
  },
  //取出数据
  get(key) {
    if (localStorage.getItem(key)=='undefined') return localStorage.getItem(key)
    return JSON.parse(localStorage.getItem(key));
  },
  // 删除数据
  remove(key) {
    localStorage.removeItem(key);
  },
  clearState(e) {
    //检测到localstorage变化跳转到登录页，所以只要把storage remvoe就可以了
    console.log(e)
    if (e.key === "steam_login_status") {
      vm.$localStorage.remove("steam_login_status");
      vm.$router.push("/");
      vm.$alert("请您重新登录", "提示", {
        confirmButtonText: "确定"
      });
    }
  }
}


import VueParticles from 'vue-particles'
Vue.use(VueParticles)
