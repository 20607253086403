<template>
  <div class="home">
    <div style="display: none; position: absolute" class="">
      <div class="aui_outer">
        <table class="aui_border">
          <tbody>
            <tr>
              <td class="aui_nw"></td>
              <td class="aui_n"></td>
              <td class="aui_ne"></td>
            </tr>
            <tr>
              <td class="aui_w"></td>
              <td class="aui_c">
                <div class="aui_inner">
                  <table class="aui_dialog">
                    <tbody>
                      <tr>
                        <td colspan="2" class="aui_header">
                          <div class="aui_titleBar">
                            <div class="aui_title" style="cursor: move"></div>
                            <a
                              class="aui_close"
                              href="javascript:/*artDialog*/;"
                              >×</a
                            >
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="aui_icon" style="display: none">
                          <div
                            class="aui_iconBg"
                            style="background: none"
                          ></div>
                        </td>
                        <td class="aui_main" style="width: auto; height: auto">
                          <div
                            class="aui_content"
                            style="padding: 20px 25px"
                          ></div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" class="aui_footer">
                          <div class="aui_buttons" style="display: none"></div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
              <td class="aui_e"></td>
            </tr>
            <tr>
              <td class="aui_sw"></td>
              <td class="aui_s"></td>
              <td class="aui_se" style="cursor: se-resize"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="less-top">
      <div class="less-top-blank"></div>
      <div class="less-top-inner">
        <div class="less-top-logo">
          <a href="https://www.nxcode.net/"
            ><img
              src="../assets/firstPage/logo.png"
              style="width: 145px"
              title="青少年创意社区"
          /></a>
        </div>
        <div class="less-top-menu">
          <ul>
            <li id="nav_1" class="on">
              <a href="https://www.nxcode.net/">首页</a>
            </li>
            <li id="nav_2">
              <a href="https://www.nxcode.net/one">校际对抗赛</a>
              <img
                class="less-top-img"
                src="../assets/firstPage/littleTool/hot.gif"
              />
            </li>
            <li id="nav_3">
              <a href="https://www.nxcode.net/course">作品天地</a>
            </li>
            <li id="nav_4">
              <a href="https://www.nxcode.net/contest">steam课堂</a>
            </li>
            <li id="nav_5">
              <a href="https://www.nxcode.net/shop">大赛活动</a>
            </li>
            <li id="nav_7">
              <a
                href="https://www.nxcode.net/online/license.html"
                target="_blank"
                >个人空间</a
              >
            </li>
          </ul>
        </div>
        <div class="less-top-func">
          <div class="less-top-search">
            <div class="less-search-control">
              <span> 模型 </span>
              <ul>
                <li data-type="1">模型</li>
                <li data-type="5">课程</li>
                <li data-type="2">文章</li>
                <li data-type="6">帮助</li>
                <li data-type="3">学校</li>
                <li data-type="4">会员</li>
              </ul>
              <form
                id="search_form"
                method="post"
                action="https://www.nxcode.net/index.php?a=search"
                onsubmit="if($(&#39;#q&#39;).val()==&#39;&#39;)return false;"
              >
                <input
                  type="hidden"
                  id="searchtype"
                  name="searchtype"
                  value="1"
                />
                <input
                  type="text"
                  id="q"
                  name="keyword"
                  value=""
                  placeholder="输入关键词，按enter确认"
                />
                <a class="less-search-hide"></a>
              </form>
            </div>
            <a class="less-search-show"></a>
          </div>
          <!-- <div class="less-uc-app">
                <span class="iconfont"></span>
                <div class="less-app-code">
                    <img src="https://img.i3done.com/statics/images/app.png">
                    <p>扫描二维码下载APP</p>
                </div>
            </div> -->

          <div class="less-top-login" id="loginArea">
            <div class="less-top-user">
              <router-link to="/login-register" class="less-user-register" target="_blank"
                >注册</router-link
              >
              |
              <a
                href="https://www.nxcode.net/user.php?m=Login"
                class="less-user-login"
                >登录</a
              >
            </div>
          </div>

          <div class="less-top-upload gradientBtn">
            <a href="https://www.nxcode.net/user.php?m=Tuzhi&amp;a=upload"
              >上传作品</a
            >
          </div>
        </div>
      </div>
      <div class="less-top-fixed" style="top: -220px; display: block">
        <div class="less-fixed-nav">
          <ul>
            <li>
              <dl></dl>
            </li>
            <li>
              <dl>
                <dd><a href="https://www.nxcode.net/one">所有模型</a></dd>
                <dd><a href="https://www.nxcode.net/fee">模型商店</a></dd>
              </dl>
            </li>
            <li>
              <dl>
                <dd><a href="https://www.nxcode.net/PK">擂台PK</a></dd>
                <dd>
                  <a href="https://www.nxcode.net/index.php?a=special_mission"
                    >抢任务</a
                  >
                </dd>
                <dd>
                  <a
                    href="https://www.nxcode.net/index.php?m=Activity&amp;a=greeting_card"
                    >3D贺卡</a
                  >
                </dd>
                <dd><a href="https://www.nxcode.net/jury">大众评审团</a></dd>
              </dl>
            </li>
            <li>
              <dl>
                <dd><a href="https://www.nxcode.net/course">课程中心</a></dd>
                <dd>
                  <a href="https://www.nxcode.net/index.php?m=train"
                    >培训中心</a
                  >
                </dd>
                <dd><a href="https://www.nxcode.net/book">教材书籍</a></dd>
                <dd>
                  <a href="https://www.nxcode.net/index.php?a=cases&amp;type=3"
                    >资讯案例</a
                  >
                </dd>
                <dd><a href="https://www.nxcode.net/study/3">官方指南</a></dd>
              </dl>
            </li>
            <li>
              <dl></dl>
            </li>
            <li>
              <dl>
                <dd><a href="https://www.nxcode.net/medal">勋章馆</a></dd>
                <dd><a href="https://www.nxcode.net/order">我的奖品</a></dd>
              </dl>
            </li>
            <li>
              <dl>
                <dd>
                  <a href="https://www.nxcode.net/online/exam.html">等级认证</a>
                </dd>
                <dd><a href="https://www.nxcode.net/tutor">创客导师</a></dd>
                <dd><a href="https://www.nxcode.net/maker">校园创客</a></dd>
              </dl>
            </li>
            <li>
              <dl></dl>
            </li>
          </ul>
        </div>
        <div class="less-fixed-func">
          <a
            href="https://www.nxcode.net/online/download.html"
            class="less-fixed-funcbtn1"
            >产品下载</a
          >
          <a href="https://www.nxcode.net/en.php" class="less-fixed-funcbtn2"
            >English</a
          >
          <a
            href="https://school.i3done.com/award.html"
            class="less-fixed-funcbtn3"
            >示范校</a
          >
          <a
            href="https://school.i3done.com/certificate"
            class="less-fixed-funcbtn5"
            >证书查询</a
          >
          <a
            href="https://school.i3done.com/index.php?m=bestschool"
            class="less-fixed-funcbtn6"
            style="width: 206px"
            >2020全国百佳创新校园评选</a
          >
        </div>
      </div>
    </div>

    <div class="newIndex-banner">
      <div
        id="showBanner_59"
        class="swiper-container swiper-container-horizontal"
        style=""
      >
        <div
          class="swiper-wrapper"
          style="
            transform: translate3d(-10172px, 0px, 0px);
            transition-duration: 0ms;
          "
        >
          <div
            class="swiper-slide swiper-slide-duplicate swiper-slide-next swiper-slide-duplicate-prev"
            style="
              background: url(../assets/firstPage/tianchong1.png) center center
                no-repeat;
              width: 2543px;
              height: 460px;
            "
            data-swiper-slide-index="2"
          >
            <div class="wa">
              <div class="operate-btn fl">
                <a
                  href="https://www.nxcode.net/online/download.html#ai"
                  target="_blank"
                  class="down-btn"
                  >下载软件</a
                ><a
                  href="https://www.nxcode.net/online/license.html"
                  target="_blank"
                  class="cart-btn"
                  >购买软件</a
                >
              </div>
            </div>
          </div>
          <div
            class="swiper-slide swiper-slide-duplicate-active"
            style="
              background: url(../assets/firstPage/tianchong1.png) center center
                no-repeat;
              width: 2543px;
              height: 460px;
            "
            data-swiper-slide-index="0"
          >
            <a
              style="display: block; width: 100%; height: 100%"
              target="_blank"
              href="https://www.nxcode.net/api.php?m=Ad&amp;a=click&amp;id=329"
            ></a>
          </div>
          <div
            class="swiper-slide"
            style="
              background: url('https://img.i3done.com/uploadfile/data/2020/10/13/5f84f77a94589.png')
                center center no-repeat;
              width: 2543px;
              height: 460px;
            "
            data-swiper-slide-index="1"
          >
            <div class="banner-inner-con wa" style="">
              <div class="banner-download">
                <ul>
                  <li><b class="school-Count">63503</b><i>学校</i></li>
                  <li><b class="student-Count">1070751</b> <i>学生</i></li>
                  <li><b class="model-Count">1632666</b> <i>作品</i></li>
                  <li><b class="course-Count">7563</b> <i>课程</i></li>
                </ul>
              </div>
            </div>
          </div>
          <div
            class="swiper-slide swiper-slide-prev swiper-slide-duplicate-next"
            style="
              background: url(../assets/firstPage/tianchong1.png) center center
                no-repeat;
              width: 2543px;
              height: 460px;
            "
            data-swiper-slide-index="2"
          >
            <div class="wa">
              <div class="operate-btn fl">
                <a
                  href="https://www.nxcode.net/online/download.html#ai"
                  target="_blank"
                  class="down-btn"
                  >下载软件</a
                ><a
                  href="https://www.nxcode.net/online/license.html"
                  target="_blank"
                  class="cart-btn"
                  >购买软件</a
                >
              </div>
            </div>
          </div>
          <div
            class="swiper-slide swiper-slide-duplicate swiper-slide-active"
            style="
              background: url(../assets/firstPage/tianchong1.png) center center
                no-repeat;
              height: 460px;
            "
            data-swiper-slide-index="0"
          >
            <a
              style="display: block; width: 100%; height: 100%"
              target="_blank"
              href="https://www.nxcode.net/api.php?m=Ad&amp;a=click&amp;id=329"
            ></a>
          </div>
        </div>
        <div
          class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets"
        >
          <span
            class="swiper-pagination-bullet swiper-pagination-bullet-active"
          ></span
          ><span class="swiper-pagination-bullet"></span
          ><span class="swiper-pagination-bullet"></span>
        </div>
        <div class="swiper-button">
          <div class="wa">
            <div class="swiper-button-prev prev"></div>
            <div class="swiper-button-next next"></div>
          </div>
        </div>
      </div>
    </div>
    <!--二屏banner位-->
    <div class="spread-banner">
      <div class="wa">
        <div class="banner-list clearfix">
          <a
            href="https://www.nxcode.net/api.php?m=ad&amp;a=click&amp;id=316"
            target="_blank"
            title="SIM云创计划"
            ><img src="../assets/firstPage/tianchong2.png" /></a
          ><a
            href="https://www.nxcode.net/api.php?m=ad&amp;a=click&amp;id=314"
            target="_blank"
            title="“我要开讲”公益招募计划"
            ><img src="../assets/firstPage/tianchong2.png" /></a
          ><a
            href="https://www.nxcode.net/api.php?m=ad&amp;a=click&amp;id=317"
            target="_blank"
            title="3D One系列课程精选"
            ><img src="../assets/firstPage/tianchong2.png" /></a
          ><a
            href="https://www.nxcode.net/api.php?m=ad&amp;a=click&amp;id=318"
            target="_blank"
            title="新手指南"
            ><img src="../assets/firstPage/tianchong2.png"
          /></a>
        </div>
      </div>
    </div>
    <div class="newIndex-main">
      <!--推荐预览和文章资讯-->
      <div class="newIndex-recommend f3f5f7">
        <div class="wa">
          <div class="recommend-box ffffff">
            <div class="re-model-con fl">
              <div class="re-model-head"></div>
              <div class="re-model fl">
                <div id="modelbox"></div>
                <div id="author">
                  <h3><a href="" target="_blank">XXX</a></h3>
                  <p>
                    <a href="" target="_blank">XXXXX</a>
                    <span
                      style="cursor: pointer"
                      onclick="common_add_care(1062088,&#39;&#39;,&#39;&#39;,&#39;&#39;,false)"
                      >XX</span
                    >
                  </p>
                </div>
                <div class="dianzan">
                  <a onclick="common_add_likes(&#39;1-4-1855064&#39;)"
                    ><img
                      src="../assets/firstPage/littleTool/intro-dianzan.png"
                  /></a>
                </div>
              </div>
            </div>
            <div class="re-news fl">
              <ul>
                <li>
                  <strong>01</strong>
                  <a
                    href="https://www.nxcode.net/news/2019/2352.html"
                    target="_blank"
                    title="【 新闻1 】XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
                    >【XXXXXX】XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX</a
                  >
                </li>
                <li>
                  <strong>02</strong>
                  <a
                    href="https://www.nxcode.net/news/2020/2601.html"
                    target="_blank"
                    title="【XXXXXX】XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
                    >【XXXXXX】XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX</a
                  >
                  <img src="../assets/firstPage/littleTool/new_1.gif" />
                </li>
                <li>
                  <strong>03</strong>
                  <a
                    href="https://www.nxcode.net/news/2020/2605.html"
                    target="_blank"
                    title="【XXXXXX】XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
                    >【XXXXXX】XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX</a
                  >
                  <img src="../assets/firstPage/littleTool/new_1.gif" />
                </li>
                <li>
                  <strong>04</strong>
                  <a
                    href="https://www.nxcode.net/course/8317.html"
                    target="_blank"
                    title="【XXXXXX】XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
                    >【XXXXXX】XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX</a
                  >
                  <img src="../assets/firstPage/littleTool/new_1.gif" />
                </li>
                <li>
                  <strong>05</strong>
                  <a
                    href="https://www.nxcode.net/train/show/331.html"
                    target="_blank"
                    title="【XXXXXX】XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
                    >【XXXXXX】XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX</a
                  >
                  <img src="../assets/firstPage/littleTool/new_1.gif" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!--作品天地-->
      <div class="newIndex-art ffffff">
        <div class="wa">
          <div class="td-main-tit">
            <div class="fl">
              <span>作品天地</span>
            </div>
            <div class="td-art-filter fl">
              <a class="td-art-a on">最新作品</a>
              <a class="td-art-a">每周精选</a>
            </div>
            <div class="td-main-link">
              <a
                href="https://www.nxcode.net/one"
                class="td-main-more td-art-more"
                target="_blank"
                >更多作品&gt;</a
              >
            </div>
          </div>
          <div class="td-art-outscroll">
            <!--最新作品-->
            <div class="td-art-scroll on">
              <div class="td-art-list clearfix on">
                <div class="td-art-moll">
                  <div class="td-art-shdow">
                    <div class="td-art-img">
                      <a
                        href="https://www.nxcode.net/model/1931409.html"
                        target="_blank"
                        ><img
                          src="../assets/firstPage/tianchong5.png"
                          title="小小兔"
                      /></a>
                    </div>
                    <div class="td-art-do">
                      <a
                        class="td-do-hand"
                        id="likes_1931409"
                        onclick="common_add_likes(&#39;1-4-1931409&#39;,this,&#39;likes_1931409&#39;,&#39;on&#39;)"
                        >443</a
                      >
                      <a
                        class="td-do-talk"
                        href="https://www.nxcode.net/model/1931409.html#comment"
                        target="_blank"
                        >1</a
                      >
                      <a
                        class="td-do-star"
                        id="favs_1931409"
                        onclick="common_add_favs(&#39;1-4-1931409&#39;,this,&#39;favs_1931409&#39;,&#39;on&#39;,0)"
                        >30</a
                      >
                    </div>
                  </div>
                  <div class="td-art-info hide_text">
                    <a
                      href="https://www.nxcode.net/model/1931409.html"
                      class="td-art-name hide_text"
                      target="_blank"
                      >XXX
                    </a>
                    <a
                      class="showusercard td-art-user"
                      data-userid="619342"
                      data-placement="right"
                      >XXXXX</a
                    >
                    <span>/</span>
                    <a
                      href="https://school.i3done.com/1002573/home.shtml"
                      target="_blank"
                      class="td-art-school"
                      >xxxxx学校</a
                    >
                  </div>
                </div>
                <div class="td-art-moll">
                  <div class="td-art-shdow">
                    <div class="td-art-img">
                      <a
                        href="https://www.nxcode.net/model/1934272.html"
                        target="_blank"
                        ><img
                          src="../assets/firstPage/tianchong5.png"
                          title="五彩南瓜罐"
                      /></a>
                    </div>
                    <div class="td-art-do">
                      <a
                        class="td-do-hand"
                        id="likes_1934272"
                        onclick="common_add_likes(&#39;1-4-1934272&#39;,this,&#39;likes_1934272&#39;,&#39;on&#39;)"
                        >488</a
                      >
                      <a
                        class="td-do-talk"
                        href="https://www.nxcode.net/model/1934272.html#comment"
                        target="_blank"
                        >1</a
                      >
                      <a
                        class="td-do-star"
                        id="favs_1934272"
                        onclick="common_add_favs(&#39;1-4-1934272&#39;,this,&#39;favs_1934272&#39;,&#39;on&#39;,0)"
                        >28</a
                      >
                    </div>
                  </div>
                  <div class="td-art-info hide_text">
                    <a
                      href="https://www.nxcode.net/model/1934272.html"
                      class="td-art-name hide_text"
                      target="_blank"
                      >XXX
                    </a>
                    <a
                      class="showusercard td-art-user"
                      data-userid="170549"
                      data-placement="right"
                      >XXXXX</a
                    >
                    <span>/</span>
                    <a
                      href="https://school.i3done.com/206281/home.shtml"
                      target="_blank"
                      class="td-art-school"
                      >xxxxx学校</a
                    >
                  </div>
                </div>
                <div class="td-art-moll">
                  <div class="td-art-shdow">
                    <div class="td-art-img">
                      <a
                        href="https://www.nxcode.net/model/1946762.html"
                        target="_blank"
                        ><img
                          src="../assets/firstPage/tianchong5.png"
                          title="电机"
                      /></a>
                    </div>
                    <div class="td-art-do">
                      <a
                        class="td-do-hand"
                        id="likes_1946762"
                        onclick="common_add_likes(&#39;1-4-1946762&#39;,this,&#39;likes_1946762&#39;,&#39;on&#39;)"
                        >114</a
                      >
                      <a
                        class="td-do-talk"
                        href="https://www.nxcode.net/model/1946762.html#comment"
                        target="_blank"
                        >0</a
                      >
                      <a
                        class="td-do-star"
                        id="favs_1946762"
                        onclick="common_add_favs(&#39;1-4-1946762&#39;,this,&#39;favs_1946762&#39;,&#39;on&#39;,0)"
                        >10</a
                      >
                    </div>
                  </div>
                  <div class="td-art-info hide_text">
                    <a
                      href="https://www.nxcode.net/model/1946762.html"
                      class="td-art-name hide_text"
                      target="_blank"
                      >XXX
                    </a>
                    <a
                      class="showusercard td-art-user"
                      data-userid="920661"
                      data-placement="right"
                      >XXXXX</a
                    >
                    <span>/</span>
                    <a
                      href="https://school.i3done.com/925385/home.shtml"
                      target="_blank"
                      class="td-art-school"
                      >xxxxx学校</a
                    >
                  </div>
                </div>
                <div class="td-art-moll noMarR">
                  <div class="td-art-shdow">
                    <div class="td-art-img">
                      <a
                        href="https://www.nxcode.net/model/1945896.html"
                        target="_blank"
                        ><img
                          src="../assets/firstPage/tianchong5.png"
                          title="马"
                      /></a>
                    </div>
                    <div class="td-art-do">
                      <a
                        class="td-do-hand"
                        id="likes_1945896"
                        onclick="common_add_likes(&#39;1-4-1945896&#39;,this,&#39;likes_1945896&#39;,&#39;on&#39;)"
                        >308</a
                      >
                      <a
                        class="td-do-talk"
                        href="https://www.nxcode.net/model/1945896.html#comment"
                        target="_blank"
                        >0</a
                      >
                      <a
                        class="td-do-star"
                        id="favs_1945896"
                        onclick="common_add_favs(&#39;1-4-1945896&#39;,this,&#39;favs_1945896&#39;,&#39;on&#39;,0)"
                        >9</a
                      >
                    </div>
                  </div>
                  <div class="td-art-info hide_text">
                    <a
                      href="https://www.nxcode.net/model/1945896.html"
                      class="td-art-name hide_text"
                      target="_blank"
                      >XXX
                    </a>
                    <a
                      class="showusercard td-art-user"
                      data-userid="1168325"
                      data-placement="right"
                      >XXXXX</a
                    >
                    <span>/</span>
                    <a
                      href="https://school.i3done.com/215696/home.shtml"
                      target="_blank"
                      class="td-art-school"
                      >xxxxx学校</a
                    >
                  </div>
                </div>
                <div class="td-art-moll">
                  <div class="td-art-shdow">
                    <div class="td-art-img">
                      <a
                        href="https://www.nxcode.net/model/1945830.html"
                        target="_blank"
                        ><img
                          src="../assets/firstPage/tianchong5.png"
                          title="零件"
                      /></a>
                    </div>
                    <div class="td-art-do">
                      <a
                        class="td-do-hand"
                        id="likes_1945830"
                        onclick="common_add_likes(&#39;1-4-1945830&#39;,this,&#39;likes_1945830&#39;,&#39;on&#39;)"
                        >294</a
                      >
                      <a
                        class="td-do-talk"
                        href="https://www.nxcode.net/model/1945830.html#comment"
                        target="_blank"
                        >0</a
                      >
                      <a
                        class="td-do-star"
                        id="favs_1945830"
                        onclick="common_add_favs(&#39;1-4-1945830&#39;,this,&#39;favs_1945830&#39;,&#39;on&#39;,0)"
                        >7</a
                      >
                    </div>
                  </div>
                  <div class="td-art-info hide_text">
                    <a
                      href="https://www.nxcode.net/model/1945830.html"
                      class="td-art-name hide_text"
                      target="_blank"
                      >XXX
                    </a>
                    <a
                      class="showusercard td-art-user"
                      data-userid="847458"
                      data-placement="right"
                      >XXXXX</a
                    >
                    <span>/</span>
                    <a
                      href="https://school.i3done.com/998961/home.shtml"
                      target="_blank"
                      class="td-art-school"
                      >xxxxx学校</a
                    >
                  </div>
                </div>
                <div class="td-art-moll">
                  <div class="td-art-shdow">
                    <div class="td-art-img">
                      <a
                        href="https://www.nxcode.net/model/1945741.html"
                        target="_blank"
                        ><img
                          src="../assets/firstPage/tianchong5.png"
                          title="阿斯顿马丁"
                      /></a>
                    </div>
                    <div class="td-art-do">
                      <a
                        class="td-do-hand"
                        id="likes_1945741"
                        onclick="common_add_likes(&#39;1-4-1945741&#39;,this,&#39;likes_1945741&#39;,&#39;on&#39;)"
                        >299</a
                      >
                      <a
                        class="td-do-talk"
                        href="https://www.nxcode.net/model/1945741.html#comment"
                        target="_blank"
                        >0</a
                      >
                      <a
                        class="td-do-star"
                        id="favs_1945741"
                        onclick="common_add_favs(&#39;1-4-1945741&#39;,this,&#39;favs_1945741&#39;,&#39;on&#39;,0)"
                        >5</a
                      >
                    </div>
                  </div>
                  <div class="td-art-info hide_text">
                    <a
                      href="https://www.nxcode.net/model/1945741.html"
                      class="td-art-name hide_text"
                      target="_blank"
                      >XXX
                    </a>
                    <a
                      class="showusercard td-art-user"
                      data-userid="1187438"
                      data-placement="right"
                      >XXXXX</a
                    >
                    <span>/</span>
                    <a
                      href="https://school.i3done.com/999563/home.shtml"
                      target="_blank"
                      class="td-art-school"
                      >xxxxx学校</a
                    >
                  </div>
                </div>
                <div class="td-art-moll">
                  <div class="td-art-shdow">
                    <div class="td-art-img">
                      <a
                        href="https://www.nxcode.net/model/1945637.html"
                        target="_blank"
                        ><img
                          src="../assets/firstPage/tianchong5.png"
                          title="开关"
                      /></a>
                    </div>
                    <div class="td-art-do">
                      <a
                        class="td-do-hand"
                        id="likes_1945637"
                        onclick="common_add_likes(&#39;1-4-1945637&#39;,this,&#39;likes_1945637&#39;,&#39;on&#39;)"
                        >150</a
                      >
                      <a
                        class="td-do-talk"
                        href="https://www.nxcode.net/model/1945637.html#comment"
                        target="_blank"
                        >0</a
                      >
                      <a
                        class="td-do-star"
                        id="favs_1945637"
                        onclick="common_add_favs(&#39;1-4-1945637&#39;,this,&#39;favs_1945637&#39;,&#39;on&#39;,0)"
                        >3</a
                      >
                    </div>
                  </div>
                  <div class="td-art-info hide_text">
                    <a
                      href="https://www.nxcode.net/model/1945637.html"
                      class="td-art-name hide_text"
                      target="_blank"
                      >XXX
                    </a>
                    <a
                      class="showusercard td-art-user"
                      data-userid="1166054"
                      data-placement="right"
                      >XXXXX-</a
                    >
                    <span>/</span>
                    <a
                      href="https://school.i3done.com/1002957/home.shtml"
                      target="_blank"
                      class="td-art-school"
                      >xxxxx学校</a
                    >
                  </div>
                </div>
                <div class="td-art-moll noMarR">
                  <div class="td-art-shdow">
                    <div class="td-art-img">
                      <a
                        href="https://www.nxcode.net/model/1945581.html"
                        target="_blank"
                        ><img
                          src="../assets/firstPage/tianchong5.png"
                          title="小熊猫 风扇"
                      /></a>
                    </div>
                    <div class="td-art-do">
                      <a
                        class="td-do-hand"
                        id="likes_1945581"
                        onclick="common_add_likes(&#39;1-4-1945581&#39;,this,&#39;likes_1945581&#39;,&#39;on&#39;)"
                        >119</a
                      >
                      <a
                        class="td-do-talk"
                        href="https://www.nxcode.net/model/1945581.html#comment"
                        target="_blank"
                        >0</a
                      >
                      <a
                        class="td-do-star"
                        id="favs_1945581"
                        onclick="common_add_favs(&#39;1-4-1945581&#39;,this,&#39;favs_1945581&#39;,&#39;on&#39;,0)"
                        >2</a
                      >
                    </div>
                  </div>
                  <div class="td-art-info hide_text">
                    <a
                      href="https://www.nxcode.net/model/1945581.html"
                      class="td-art-name hide_text"
                      target="_blank"
                      >XXX
                    </a>
                    <a
                      class="showusercard td-art-user"
                      data-userid="1080871"
                      data-placement="right"
                      >XXXXX</a
                    >
                    <span>/</span>
                    <a
                      href="https://school.i3done.com/1002957/home.shtml"
                      target="_blank"
                      class="td-art-school"
                      >xxxxx学校</a
                    >
                  </div>
                </div>
                <div class="td-art-moll">
                  <div class="td-art-shdow">
                    <div class="td-art-img">
                      <a
                        href="https://www.nxcode.net/model/1945549.html"
                        target="_blank"
                        ><img
                          src="../assets/firstPage/tianchong5.png"
                          title="火车头"
                      /></a>
                    </div>
                    <div class="td-art-do">
                      <a
                        class="td-do-hand"
                        id="likes_1945549"
                        onclick="common_add_likes(&#39;1-4-1945549&#39;,this,&#39;likes_1945549&#39;,&#39;on&#39;)"
                        >170</a
                      >
                      <a
                        class="td-do-talk"
                        href="https://www.nxcode.net/model/1945549.html#comment"
                        target="_blank"
                        >0</a
                      >
                      <a
                        class="td-do-star"
                        id="favs_1945549"
                        onclick="common_add_favs(&#39;1-4-1945549&#39;,this,&#39;favs_1945549&#39;,&#39;on&#39;,0)"
                        >5</a
                      >
                    </div>
                  </div>
                  <div class="td-art-info hide_text">
                    <a
                      href="https://www.nxcode.net/model/1945549.html"
                      class="td-art-name hide_text"
                      target="_blank"
                      >XXX
                    </a>
                    <a
                      class="showusercard td-art-user"
                      data-userid="1166054"
                      data-placement="right"
                      >XXXXX-</a
                    >
                    <span>/</span>
                    <a
                      href="https://school.i3done.com/1002957/home.shtml"
                      target="_blank"
                      class="td-art-school"
                      >xxxxx学校</a
                    >
                  </div>
                </div>
                <div class="td-art-moll">
                  <div class="td-art-shdow">
                    <div class="td-art-img">
                      <a
                        href="https://www.nxcode.net/model/1945500.html"
                        target="_blank"
                        ><img
                          src="../assets/firstPage/tianchong5.png"
                          title="工作台"
                      /></a>
                    </div>
                    <div class="td-art-do">
                      <a
                        class="td-do-hand"
                        id="likes_1945500"
                        onclick="common_add_likes(&#39;1-4-1945500&#39;,this,&#39;likes_1945500&#39;,&#39;on&#39;)"
                        >123</a
                      >
                      <a
                        class="td-do-talk"
                        href="https://www.nxcode.net/model/1945500.html#comment"
                        target="_blank"
                        >0</a
                      >
                      <a
                        class="td-do-star"
                        id="favs_1945500"
                        onclick="common_add_favs(&#39;1-4-1945500&#39;,this,&#39;favs_1945500&#39;,&#39;on&#39;,0)"
                        >5</a
                      >
                    </div>
                  </div>
                  <div class="td-art-info hide_text">
                    <a
                      href="https://www.nxcode.net/model/1945500.html"
                      class="td-art-name hide_text"
                      target="_blank"
                      >XXX
                    </a>
                    <a
                      class="showusercard td-art-user"
                      data-userid="1166054"
                      data-placement="right"
                      >XXXXX-</a
                    >
                    <span>/</span>
                    <a
                      href="https://school.i3done.com/1002957/home.shtml"
                      target="_blank"
                      class="td-art-school"
                      >xxxxx学校</a
                    >
                  </div>
                </div>
                <div class="td-art-moll">
                  <div class="td-art-shdow">
                    <div class="td-art-img">
                      <a
                        href="https://www.nxcode.net/model/1950105.html"
                        target="_blank"
                        ><img
                          src="../assets/firstPage/tianchong5.png"
                          title="岳阳楼"
                      /></a>
                    </div>
                    <div class="td-art-do">
                      <a
                        class="td-do-hand"
                        id="likes_1950105"
                        onclick="common_add_likes(&#39;1-4-1950105&#39;,this,&#39;likes_1950105&#39;,&#39;on&#39;)"
                        >492</a
                      >
                      <a
                        class="td-do-talk"
                        href="https://www.nxcode.net/model/1950105.html#comment"
                        target="_blank"
                        >1</a
                      >
                      <a
                        class="td-do-star"
                        id="favs_1950105"
                        onclick="common_add_favs(&#39;1-4-1950105&#39;,this,&#39;favs_1950105&#39;,&#39;on&#39;,0)"
                        >24</a
                      >
                    </div>
                  </div>
                  <div class="td-art-info hide_text">
                    <a
                      href="https://www.nxcode.net/model/1950105.html"
                      class="td-art-name hide_text"
                      target="_blank"
                      >XXX
                    </a>
                    <a
                      class="showusercard td-art-user"
                      data-userid="551633"
                      data-placement="right"
                      >XXXXX</a
                    >
                    <span>/</span>
                    <a
                      href="https://school.i3done.com/695714/home.shtml"
                      target="_blank"
                      class="td-art-school"
                      >xxxxx学校</a
                    >
                  </div>
                </div>
                <div class="td-art-moll noMarR">
                  <div class="td-art-shdow">
                    <div class="td-art-img">
                      <a
                        href="https://www.nxcode.net/model/1950081.html"
                        target="_blank"
                        ><img
                          src="../assets/firstPage/tianchong5.png"
                          title="小铁"
                      /></a>
                    </div>
                    <div class="td-art-do">
                      <a
                        class="td-do-hand"
                        id="likes_1950081"
                        onclick="common_add_likes(&#39;1-4-1950081&#39;,this,&#39;likes_1950081&#39;,&#39;on&#39;)"
                        >828</a
                      >
                      <a
                        class="td-do-talk"
                        href="https://www.nxcode.net/model/1950081.html#comment"
                        target="_blank"
                        >2</a
                      >
                      <a
                        class="td-do-star"
                        id="favs_1950081"
                        onclick="common_add_favs(&#39;1-4-1950081&#39;,this,&#39;favs_1950081&#39;,&#39;on&#39;,0)"
                        >66</a
                      >
                    </div>
                  </div>
                  <div class="td-art-info hide_text">
                    <a
                      href="https://www.nxcode.net/model/1950081.html"
                      class="td-art-name hide_text"
                      target="_blank"
                      >XXX
                    </a>
                    <a
                      class="showusercard td-art-user"
                      data-userid="551633"
                      data-placement="right"
                      >XXXXX.</a
                    >
                    <span>/</span>
                    <a
                      href="https://school.i3done.com/695714/home.shtml"
                      target="_blank"
                      class="td-art-school"
                      >xxxxx学校</a
                    >
                  </div>
                </div>
              </div>

              <div class="td-art-list clearfix"></div>
              <div class="td-art-pages">
                <div class="pages">
                  <a class="current">1</a>
                  <a>2</a>
                  <a>3</a>
                  <a href="https://www.nxcode.net/one#all" target="_blank"
                    >...</a
                  >
                </div>
              </div>
            </div>
            <!--猜你喜欢-->
            <div class="td-art-scroll">
              <div class="td-art-list clearfix on"></div>
            </div>

            <!--模型商店-->
          </div>
        </div>
      </div>
      <!--创客课程-->
      <div class="newIndex-course">
        <div class="wa">
          <div class="td-main-tit">
            <div class="fl">
              <span>steam课程</span>
              <i>· </i>
            </div>
            <div class="td-main-link">
              <a
                href="https://www.nxcode.net/course"
                class="td-main-more"
                target="_blank"
                >学习更多&gt;</a
              >
            </div>
          </div>
          <div class="td-course-outscroll">
            <div class="td-course-summary clearfix">
              <div class="course-card">
                <div class="course-intro course-intro1">
                  <a
                    href="https://www.nxcode.net/index.php?m=course&amp;a=topic_show&amp;id=1"
                    target="_blank"
                  >
                    <div class="item-txt">
                      <h1>Scratch</h1>
                      <h6></h6>
                      <p>
                        <!-- <i>8</i>个课程 -->
                        <em>·</em><i>XXX</i>人学习
                      </p>
                    </div>
                    <div class="item-bg">
                      <img src="../assets/firstPage/tianchong5.png" />
                    </div>
                  </a>
                </div>
                <div class="course-soft">
                  <a
                    href="https://www.nxcode.net/online/download.html#3done"
                    target="_blank"
                  >
                    <!-- <p>适用软件：3D One</p> -->
                    <p>适用学龄：小学</p>
                    <!-- <i class="soft-icon i3done"></i> -->
                  </a>
                </div>
              </div>
              <div class="course-card">
                <div class="course-intro course-intro2">
                  <a
                    href="https://www.nxcode.net/index.php?m=course&amp;a=topic_show&amp;id=2"
                    target="_blank"
                  >
                    <div class="item-txt">
                      <h1>Python</h1>
                      <h6></h6>
                      <p>
                        <!-- <i>5</i>个课程 -->
                        <em>·</em><i>XXX</i>人学习
                      </p>
                    </div>
                    <div class="item-bg">
                      <img src="../assets/firstPage/tianchong5.png" />
                    </div>
                  </a>
                </div>
                <div class="course-soft">
                  <a
                    href="https://www.nxcode.net/online/download.html#plus"
                    target="_blank"
                  >
                    <!-- <p>适用软件：3D One Plus</p> -->
                    <p>适用学龄：中学</p>
                    <!-- <i class="soft-icon i3done-plus"></i> -->
                  </a>
                </div>
              </div>
              <div class="course-card">
                <div class="course-intro course-intro3">
                  <a
                    href="https://www.nxcode.net/index.php?m=course&amp;a=topic_show&amp;id=3"
                    target="_blank"
                  >
                    <div class="item-txt">
                      <h1>智能硬件</h1>
                      <h6></h6>
                      <p>
                        <!-- <i>12</i>个课程 -->
                        <em>·</em><i>XXX</i>人学习
                      </p>
                    </div>
                    <div class="item-bg">
                      <img src="../assets/firstPage/tianchong5.png" />
                    </div>
                  </a>
                </div>
                <div class="course-soft">
                  <a
                    href="https://www.nxcode.net/online/download.html#cut"
                    target="_blank"
                  >
                    <!-- <p>适用软件：3D One Cut</p> -->
                    <p>适用学龄：小学、中学</p>
                    <!-- <i class="soft-icon i3done-cut"></i> -->
                  </a>
                </div>
              </div>
              <div class="course-card">
                <div class="course-intro course-intro4">
                  <a
                    href="https://www.nxcode.net/index.php?m=course&amp;a=topic_show&amp;id=39"
                    target="_blank"
                  >
                    <div class="item-txt">
                      <h1>大颗粒</h1>
                      <h6></h6>
                      <p>
                        <!-- <i>3</i>个课程 -->
                        <em>·</em><i>XXX</i>人学习
                      </p>
                    </div>
                    <div class="item-bg">
                      <img src="../assets/firstPage/tianchong5.png" />
                    </div>
                  </a>
                </div>
                <div class="course-soft">
                  <a
                    href="https://www.nxcode.net/online/download.html#ai"
                    target="_blank"
                  >
                    <!-- <p>适用软件：3D One AI</p> -->
                    <p>适用学龄：小学</p>
                    <!-- <i class="soft-icon i3done-ai"></i> -->
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--大赛活动/任务主题/PK-->
    <div class="newIndex-contest">
      <div class="wa">
        <div class="td-main-tit">
          <div class="fl">
            <span>大赛活动</span>
            <i>· </i>
          </div>
          <div class="td-main-link">
            <a
              href="https://www.nxcode.net/contest"
              class="td-main-more"
              target="_blank"
              >更多大赛&gt;</a
            >
          </div>
        </div>
        <div class="td-acti-main">
          <div class="td-acti-adv">
            <div class="td-adv-left">
              <a
                href="https://www.nxcode.net/contest/show/134.html"
                target="_blank"
              >
                <img
                  src="../assets/firstPage/firstShort.png"
                  title="宁夏回族自治区创意编程与智能设计大赛"
              /></a>
            </div>
            <div class="td-adv-right">
              <a
                href="https://www.nxcode.net/contest/show/158.html"
                target="_blank"
              >
                <img
                  src="../assets/firstPage/firstShort1.png"
                  title="银川市青少年科技创新大赛本地特色项目（人工智能挑战赛）"
              /></a>
            </div>
          </div>

          <div class="td-acti-pk">
            <div class="td-pk-left">
              <h2>月季对抗赛实时PK排名</h2>
              <ul>
                <li>
                  <b class="topic">1</b>
                  <a
                    class="showusercard"
                    data-userid="170537"
                    data-placement="right"
                    >XXXXx</a
                  >
                  <span><em>3</em>分</span>
                </li>
                <li>
                  <b class="topic">2</b>
                  <a
                    class="showusercard"
                    data-userid="782070"
                    data-placement="right"
                    >XXXx</a
                  >
                  <span><em>3</em>分</span>
                </li>
                <li>
                  <b class="topic">3</b>
                  <a
                    class="showusercard"
                    data-userid="1027410"
                    data-placement="right"
                    >XXXx</a
                  >
                  <span><em>2</em>分</span>
                </li>
                <li>
                  <b>4</b>
                  <a
                    class="showusercard"
                    data-userid="1084162"
                    data-placement="right"
                    >XXXx</a
                  >
                  <span><em>2</em>分</span>
                </li>
                <li>
                  <b>5</b>
                  <a
                    class="showusercard"
                    data-userid="919552"
                    data-placement="right"
                    >XXXx</a
                  >
                  <span><em>1</em>分</span>
                </li>
              </ul>
              <div class="td-vote-submit">
                <a href="https://www.nxcode.net/PK" target="_blank"
                  >参加校际对抗赛</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--示范校-->
    <div class="newIndex-school">
      <div class="wa">
        <div class="td-main-tit">
          <div class="fl">
            <span>校元战队</span>
            <i>· </i>
          </div>
          <div class="td-main-link">
            <a
              href="https://www.nxcode.net/news/2016/795.html"
              class="td-main-more"
              target="_blank"
              >申请校元战队</a
            >
            <em>/</em>
            <a
              href="https://school.i3done.com/award.html"
              class="td-main-more"
              target="_blank"
              >更多校元战队&gt;</a
            >
          </div>
        </div>
        <div class="newIndex-school-outscroll">
          <div class="newIndex-school-adv clearfix">
            <a href="https://school.i3done.com/award.html" target="_blank">
              <img src="../assets/firstPage/tianchong3.png" />
            </a>
            <a href="https://www.nxcode.net/online/sim.html" target="_blank">
              <img src="../assets/firstPage/tianchong3.png" />
            </a>
          </div>
          <div class="newIndex-school-list clearfix">
            <div class="td-sch-moll">
              <a
                href="https://school.i3done.com/999364/home.shtml"
                style="margin-top: 0"
                target="_blank"
              >
                <img
                  src="../assets/firstPage/schoolLogo/外国语实验.png"
                  title="银川外国语实验学校"
                />
              </a>
              <a
                href="https://school.i3done.com/999364/home.shtml"
                title="银川外国语实验学校"
                target="_blank"
                >银川外国语实验学校</a
              >
              <div class="td-sch-info">
                队员数：<i>XXX</i><span></span>作品数：<i>XXX</i>
              </div>
            </div>
            <div class="td-sch-moll">
              <a
                href="https://school.i3done.com/30261/home.shtml"
                style="margin-top: 0"
                target="_blank"
              >
                <img
                  src="../assets/firstPage/schoolLogo/changqing.png"
                  title="宁夏长庆高级中学"
                />
              </a>
              <a
                href="https://school.i3done.com/30261/home.shtml"
                title="宁夏长庆高级中学"
                target="_blank"
                >宁夏长庆高级中学</a
              >
              <div class="td-sch-info">
                队员数：<i>XXX</i><span></span>作品数：<i>XXX</i>
              </div>
            </div>
            <div class="td-sch-moll">
              <a
                href="https://school.i3done.com/996453/home.shtml"
                style="margin-top: 0"
                target="_blank"
              >
                <img src="../assets/firstPage/tianchong3.png" title="XXXXX" />
              </a>
              <a
                href="https://school.i3done.com/996453/home.shtml"
                title="XXXXX"
                target="_blank"
                >XXXXX</a
              >
              <div class="td-sch-info">
                队员数：<i>XXX</i><span></span>作品数：<i>XXX</i>
              </div>
            </div>
            <div class="td-sch-moll">
              <a
                href="https://school.i3done.com/9231XXXX07/home.shtml"
                style="margin-top: 0"
                target="_blank"
              >
                <img
                  src="../assets/firstPage/tianchong3.png"
                  title="XXXXXXXX"
                />
              </a>
              <a
                href="https://school.i3done.com/923107/home.shXXXtml"
                title="XXXXX"
                target="_blank"
                >XXXXX</a
              >
              <div class="td-sch-info">
                队员数：<i>XXX</i><span></span>作品数：<i>XXX</i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    HelloWorld,
  },
};
</script>

<style  scoped>
@import url(../assets/css/top.css);

@import url(../assets/css/global.css);

@import url(../assets/css/newIndex.css);

@import url(../assets/css/artDialog.css);
</style>
