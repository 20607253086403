<template>
  <div id="app">
    <!-- <pythonIde ></pythonIde> -->
    <router-view></router-view>
  </div>
</template>

<script>
import pythonIde from "./components/python_ide/main.vue";
import Home from "./views/Home.vue";
import { DetectBrowser } from "./utils/utils";
import { noF12 } from "./utils/noF12";

export default {
  name: "App",
  components: {
    pythonIde,
    Home,
  },
   created() {
     noF12();
  },
  mounted() {
    //检查浏览器
    DetectBrowser();
    let counter = 0;
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "hidden") {
        return;
      }
      // let $counter = document.querySelector('#counter')
      // let counter = ~~($counter.innerHTML)
      if (!this.$localStorage.get("login_status_username")) {
        this.$http
          .post("/exam", {
            mode: "outScreen",
            username: this.$localStorage.get("login_status_username"),
            password: this.$localStorage.get("login_status_token"),
          })
          .then((res) => {});
        this.$message({
          message: "您的切屏记录已提交！",
          center: true,
          type: "error",
          showClose: true,
          duration: 2000,
        });
        console.log(counter++);
      }

      // $counter.innerHTML = counter + 1
    });

    let version = "";
    var db = this.$serverLess.database();
    const watcher = db
      .collection("exam-support")
      .doc("defad14563288a7e0022369869a5bfca")
      .watch({
        onChange: (snapshot) => {
          console.log(snapshot.docs[0].version);
          let net_version = snapshot.docs[0].version;
          let local_version = this.$localStorage.get("exam_version");
          if (local_version < net_version) {
            this.$localStorage.set("exam_version", net_version);
            this.$message({
              message: "检测到系统更新页面将在5秒后刷新",
              center: true,
              type: "warning",
              showClose: true,
              duration: 2000,
            });

            setTimeout(() => {
              location.reload();
            }, 5000);
          }
        },
        onError: (err) => {
          this.$message({
            message: "与后台监控系统链接中断，建议刷新页面！",
            center: true,
            type: "error",
            showClose: true,
            duration: 2000,
          });
        },
      });
  },
};
</script>
<style>
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.div-point {
  cursor: pointer;
}
</style>
