import Vue from 'vue'
import Vuex from 'vuex'
import { vm } from '../main'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    choice: [],  //保存各题号及其对应的选项
    fillBlank: [],
    judges:[],
    programming: [],
    multipleChoice:[],
    scorePerQues: 0,
    score: 0,
    login_state: false
  },
  mutations: {
    updateRecord(state, mydata) {
      // console.log(vm)
      let choiceStorage = vm.$localStorage.get(mydata.type)
      choiceStorage = choiceStorage === null ? [] : choiceStorage
      choiceStorage[mydata.choice.quesIndex] = mydata.choice
      state[mydata.type] = choiceStorage
      vm.$localStorage.set(mydata.type, choiceStorage)
    },
    getLoginState(state) {
      let username = vm.$localStorage.get("login_status_token")
      let password = vm.$localStorage.get("login_status_username")
      if (username != '' && password != '') return true
      else return false
    }
  },
  getters: {
    getLoginState(state) {
      let username = vm.$localStorage.get("login_status_token")
      let password = vm.$localStorage.get("login_status_username")
      console.log(username,password)
      if (username != null && password != null) return true
      else return false
    }
  },
  actions: {}
})
