export function noF12() {

    setInterval(() => {
        debugger;
    }, 50);

    window.onkeydown = window.onkeyup = window.onkeypress = function (event) {
        //禁止F12
        if (event.keyCode == 123) {
            event.preventDefault(); //阻止默认事件行为
            window.event.returnValue = false;
        }
    }

}