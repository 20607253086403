<template>
  <div class="python_ide ide-layout">
    <div class="ide-layout__header">
      <img src="../../assets/logo_word.png" class="ide-layout__logo" alt="" />

      <h1 class="ide-layout__title">Python在线编辑器</h1>

      <div class="ide-layout__menu">
        <!-- <div class="te-share div-point">分 享</div> -->
        <el-button type="success" plain>分 享</el-button>
        <el-button>保 存</el-button>
      </div>
      <span class="eyecare">
        <div class="eyecare_icon"></div>
      </span>
    </div>
    <div class="ide-layout__main">
      <code-editor
        :options="options"
        :value="content"
        ref="editor"
        @contentChange="contentChange"
        style="height: 100%; width: 70%"
      ></code-editor>

      <div class="console">
        <!-- 绘图框 -->

        <div id="mycanvas" height="600" width="800"></div>
        <!-- 输出框 -->

        <pre id="output"></pre>

        <div class="button-tool">
          <!-- <button id="runbutton" class="tool-item run"><span class="ux-scratch-icon-ide-run"></span>运行</button> -->
          <el-button
            type="warning"
            style="height: 19px"
            icon="el-icon-video-play"
            @click="runit"
            >运行</el-button
          >
        </div>
      </div>
    </div>

    <!-- <h1>{{ msg }}</h1>
    <h3>Try This</h3> -->
    <!-- <form>
      <textarea id="yourcode" cols="80" rows="10">
        import turtle
        print('hello')
        t = turtle.Turtle()
        t.color('red')
        t.forward(75)
      </textarea>
      <br />
      <button type="button" @click="runit">Run</button>
    </form> -->
  </div>
</template>
  
<script>
import CodeEditor from "./CodeEditor"; //引入插件

export default {
  name: "python_ide",
  props: {
    msg: String,
  },
  data() {
    return {
      content: "",
      myCode: "",
      options: {
        language: "python",
        theme: "vs",
        readOnly: false,
      },
    };
  },
  components: {
    CodeEditor,
  },
  mounted() {
    let workId = window.location.search.split("=")[1];
    console.log(window.location.search, workId);

    this.$http
      .post("/work", {
        workId,
        userId: "admin",
        mode: "getWork",
      })
      .then((res) => {
        console.log(res.data);
        this.$refs.editor.setValue(res.data);
      });
    // const skulpt_js = document.createElement("script");
    // const skulpt_stdlib_js = document.createElement("script");
    // skulpt_js.type = "text/javascript";
    // skulpt_stdlib_js.type = "text/javascript";
    // skulpt_js.src = "dist/skulpt.min.js";
    // skulpt_stdlib_js.src = "dist/skulpt-stdlib.js";
    // document.body.appendChild(skulpt_js);
    // document.body.appendChild(skulpt_stdlib_js);
  },
  methods: {
    outf(text) {
      var mypre = document.getElementById("output");
      mypre.innerHTML = mypre.innerHTML + text;
    },
    builtinRead(x) {
      if (
        Sk.builtinFiles === undefined ||
        Sk.builtinFiles["files"][x] === undefined
      )
        throw "File not found: '" + x + "'";
      return Sk.builtinFiles["files"][x];
    },
    contentChange(e) {
      this.myCode = e;
      // console.log(this.content);
    },
    runit() {
      var prog = this.myCode;
      // var prog = document.getElementById("yourcode").value;
      var mypre = document.getElementById("output");
      mypre.innerHTML = "";
      Sk.pre = "output";
      Sk.configure({
        output: this.outf,
        read: this.builtinRead,
        __future__: Sk.python3,
      });

      (Sk.TurtleGraphics || (Sk.TurtleGraphics = {})).target = "mycanvas"; //调整画布大小在skulpt-stdlib搜 width:600这里就可以修改了,width,height可以修改

      var myPromise = Sk.misceval.asyncToPromise(function () {
        return Sk.importMainWithBody("<stdin>", false, prog, true);
      });

      myPromise.then(
        (mod) => {
          Sk.configure({
            output: this.outf("success"),
            read: this.builtinRead,
            __future__: Sk.python3,
          });
          console.log("success");
        },
        (err) => {
          Sk.configure({
            output: this.outf("\n" + err.toString()),
            read: this.builtinRead,
            __future__: Sk.python3,
          });
          console.log(err.toString());
        }
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ide-layout {
  width: 100%;
  min-width: 1024px;
  height: 100vh;
  min-height: 560px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
}
.ide-layout__header {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  height: 58px;
  line-height: 32px;
  background-color: #ff9f12;
}
.ide-layout__logo {
  display: inline-block;
  width: 180px;
  vertical-align: middle;
  background-size: 100%;
  cursor: pointer;
}
.ide-layout__title {
  position: absolute;
  left: 50%;
  margin: 0;
  height: 58px;
  line-height: 58px;
  font-size: 24px;
  color: #fffad5;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
}
.ide-layout__menu {
  display: flex;
  align-items: center;
  position: absolute;
  height: 58px;
  right: 45px;
}
.ide-layout__main {
  display: flex;
  height: 100%;
}
.console {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 600px;
  height: 100%;
  min-height: 560px;
  background-color: #fdf7e7;
  border-left: 1px solid #87746d;
}
#mycanvas {
  width: 600px;
  height: 60%;
  min-height: calc(560px * 0.6);
  border-bottom: 1px solid #87746d;
}
#output {
  height: 20%;
  min-height: calc(560px * 0.20);
  background: #41444e;
  color: white;
  text-align: left;
  z-index: 99;
}

.button-tool {
  width: 100%;
  height: 98px;
  padding: 18px 28px;
  background: #fffbf0;
  text-align: left;
  border-top: 1px solid #87746d;
  overflow: hidden;
}
</style>

