import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  //首页这个版本先不上
  //注册页
  {
    path: '/fileMode',
    name: 'fileMode',
    component: () => import('../views/fileMode.vue')
  },
  {
    path: '/pythonIde',
    name: 'pythonIde',
    component: () => import('../components/python_ide/main.vue')
  },
   {
    path: '/scratchIde',
    name: 'scratchIde',
    component: () => import('../components/scratch_ide/main.vue')
  },
  {
    path: '/loginRegister',
    name: 'LoginRegister',
    component: () => import('../views/Register.vue')
  },
  //校际对抗赛页,现在再根路径以后主页上线用/challenge路径
  // {
  //   path: '/',
  //   name: 'Challenge',
  //   component: () => import('../views/Challenge.vue')
  // },
   {
    path: '/examine',
    name: 'Examine',
    component: () => import('../views/question.vue')
  }, 
  {
    path: '/notice',
    name: 'Notice',
    component: () => import('../views/notice.vue')
  },
  {
    path: '/',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('../views/test1.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
